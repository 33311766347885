.purpose-content-block {
  &--3,
  &--2,
  &--1 {
    @include media-breakpoint-up(xl) {
      display: flex;
    }
  }

  &--3,
  &--2 {
    flex-direction: column;
  }

  &--2 {
    justify-content: flex-end;
  }

  &--5 {
    .purpose-content-block {
      flex-direction: row-reverse;
    }
  }

  &--3 {
    @include media-breakpoint-up(xl) {
      padding-right: map-get($spacers, 5);

      .purpose-content-block__main {
        // margin-left: map-get($spacers, 6);
      }
    }
  }

  // Every list item width
  // .purpose-page__blocks__list__item:nth-child(3n+ 3) & {
  .purpose-page__blocks__list__item--3 & {
    &.purpose-content-block--3 {
      justify-content: flex-end;
    }
  }

  &__main {
    @include media-breakpoint-between(md, md) {
      // @media (max-width: 960px) {
      max-width: 500px;
      margin: 0 auto;
    }

    @include media-breakpoint-between(lg, lg) {
      max-width: 650px;
      margin: 0 auto;
    }

    @include media-breakpoint-up(xl) {
      width: 100vw;
      max-width: 100%;
      // margin: 0 auto 0 auto;
      margin: 0;

      &__inner {
        max-width: 600px;
        margin: 0 auto;
      }

      .purpose-page__blocks__list__item--3 &,
      .page__blocks__list__item:nth-child(3n) .purpose-content-block--3 &,
      .purpose-content-block--2 & {
        width: 45vw;
        margin: 0 auto 0 0;
      }
    }
  }
}

.purpose-content-block__teasers {
  @include media-breakpoint-down(sm) {
    margin-right: -12px;
    margin-left: -12px;
  }

  @include media-breakpoint-between(md,lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-auto-flow: dense;
    grid-column-gap: map-get($spacers, 2);
    grid-row-gap: map-get($spacers, 2);
  }

  @include media-breakpoint-up(xl) {
    &.images--2,
    &.images--3 {
      width: 100%;
      display: grid;
      grid-template-columns: auto 30vw;
      grid-template-rows: 1fr;
      grid-auto-flow: dense;
      grid-column-gap: map-get($spacers, 6);
      grid-row-gap: map-get($spacers, 6);
    }

    &.images--3 {
      .purpose-page__blocks__list__item--3 & {
        grid-template-columns: 30vw auto;
      }
    }

    &.images--2 {
      grid-template-columns: 30vw auto;
    }

    &.images--1 {
      width: 45vw;
      // max-width: 680px;
      // margin: 0 auto;
    }
  }
}

.purpose-content-block__teasers__item {
  position: relative;
  width: 100%;

  @include media-breakpoint-down(lg) {
    // margin-bottom: map-get($spacers, 1);

    .images--3 & {
      &:last-of-type {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    display: inline-block;
    margin: 0 0 auto auto;

    .images--3 & {
      &.index--0 {
        max-width: 450px;

        .purpose-teaser__crop::before {
          padding-top: 100%;
        }
      }

      &.index--1 {
        .purpose-teaser__crop {
          max-height: 88vh;

          &::before {
            padding-top: 150%;
          }
        }
      }

      &.index--2 {
        // max-width: 50vw;
        margin-top: calc(0px - var(--purposeMain));

        .purpose-teaser__crop {
          max-height: 70vh;

          &::before {
            padding-top: 66%;
          }
        }
      }
    }

    .purpose-page__blocks__list__item--3 .images--3 & {
      &.index--0 {
        margin-top: auto;
        margin-bottom: 0;
        max-width: none;
      }

      &.index--1 {
        .purpose-teaser__crop::before {
          padding-top: 66%;
        }
      }

      &.index--2,
      &.index--0 {
        .purpose-teaser__crop {
          max-height: 88vh;

          &::before {
            padding-top: 100%;
          }
        }
      }

      &.index--2 {
        width: 120%;
        margin-top: 0;
        transform: translateX(25%);
      }
    }

    .images--1 & {
      .purpose-teaser__crop {
        max-height: 96vh;

        &::before {
          padding-top: 150%;
        }
      }
    }

    .images--2 & {
      .purpose-teaser__crop {
        max-height: 70vh;

        &::before {
          padding-top: 66%;
        }
      }

      &.index--0 .purpose-teaser__crop {
        max-height: 96vh;

        &::before {
          padding-top: 150%;
        }
      }
    }
  }

  // 
  &.index--2 {
    @include media-breakpoint-between(lg, xl) {
      .purpose-teaser {
        margin-right: map-get($spacers, 7);
      }
    }
  }
}

.purpose-content-block {
  .block-content {
    &__text {
      @include media-breakpoint-between(md, md) {
        margin-bottom: map-get($spacers, 6);
      }
    }
  }
}
