
.purpose-page {
  &__blocks {
    @include media-breakpoint-down(sm) {
      padding-top: map-get($spacers, 3);
    }

    @include media-breakpoint-between(md, md) {
      padding-top: map-get($spacers, 6);
    }

    @include media-breakpoint-up(lg) {
      padding-top: map-get($spacers, 6);
    }
  }

  &__blocks__list__item {
    margin-bottom: map-get($spacers, 4);

    @include media-breakpoint-between(md, lg) {  
      margin-bottom: map-get($spacers, 7);
      //padding-bottom: map-get($spacers, 4);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: map-get($spacers, 7);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__awards {
    --publication-card-height: 208px;
    --publication-card-width: 280px;
    --publication-card-image-padding: 24px 24px;

    margin-top: map-get($spacers, 6);

    @include media-breakpoint-up(md) {
      padding-bottom: map-get($spacers, 8);
    }

    .button--border {
      min-width: var(--publication-card-width);
      // padding-left: 0;
      // padding-right: 0;

      @include media-breakpoint-between(md, md) {
        min-width: 260px;
      }

      @include media-breakpoint-between(lg, lg) {
        min-width: 280px;
      }
    }

    .awards-list__item {
      padding-left: map-get($spacers, 2);
    }

    .award-year .h3 {
      min-width: 4ex;
    }

    .button-link.color-gray-500 {
      color: gray-color(500);
      opacity: .7;
    }

    @include media-breakpoint-down(sm) {
      .col-md-12 {
        padding-right: 0;
        margin-bottom: map-get($spacers, 4);

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .award-year.type--dummy {
        gap: 0;
        display: block;

        .button.button--border {
          width: 100%;
        }

        .h3 {
          display: none;
        }

        .awards-list__item {
          padding-left: 0;
          margin-bottom: 0;
        }
      }
    }

    @include media-breakpoint-between(md, md) {
      .publication-card {
        flex-direction: column;
        padding-right: map-get($spacers, 3);
        margin-bottom: map-get($spacers, 4);
      }

      .publication-card__figure {
        width: 100%;
        margin-bottom: map-get($spacers, 1);
      }

      .button--border {
        min-width: 260px;
      }
    }

    @include media-breakpoint-between(lg, lg) {
      --publication-card-height: 180px;
      --publication-card-width: 200px;
    }
  }

  .purpose-page__publications {
    @include media-breakpoint-down(sm) {
      margin-bottom: map-get($spacers, 7);
    }
  }
}
.purpose-page__blocks__list__item--1{
.purpose-content-block__main__inner{
  max-width: 802px;
}
}