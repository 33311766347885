.text-wide-content-block{
  &__inner {
		max-width: 950px;
  	margin: 0 auto;
	}

  &__text{
    line-height: 1.3;
    font-size: 22px;

		@include media-breakpoint-down(md) {
			font-size: 18px;
		}
  }
}