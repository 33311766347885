.text-max-width {
	max-width: 600px;
}

.purpose-content-block-v2 {
	--image-inset: 0px;

	.block-content__title {
		padding-bottom: 0;
	}

	@include media-breakpoint-down(sm) {
		&__image,
		&__image-secondary {
			margin-bottom: map-get($spacers, 4);
		}
	}
	@include media-breakpoint-up(md) {
		--image-inset: 100px;
	}
}

@include media-breakpoint-up(sm) {
	.padding-inset-image {
		padding-bottom: calc( var(--image-inset) + 30px);
	}
}

.inset-image {
	margin-top: calc(var(--image-inset) * -1);
}

// Random order manipulations

.purpose-content-block-v2.index--0 {
	@include media-breakpoint-down(xs) {
		.purpose-content-block-v2__image {
			order: 1;
		}
		.purpose-content-block-v2__main {
			order: 3;
		}
		.purpose-content-block-v2__image-secondary {
			order: 2;
		}
	}
}
.block--purpose+.block--purpose:nth-child(odd) {
	//background-color: gray;
	.purpose-content-block-v2 {
		//@include media-breakpoint-up(sm) {
			&__image {
				order: 1;
			}
			&__main {
				order: 2;
			}
			&__image-secondary {
				order: 3;
			}
		//}
	}
}

.purpose-content-block-v2.has--random-exceptions {
	//background-color: red;

	@include media-breakpoint-up(sm) {
		.purpose-content-block-v2__main {
			padding-top: 100px;
		}
	}
	@include media-breakpoint-up(sm) {
		--image-inset: 200px;
	}

	@include media-breakpoint-down(xs) {
		.purpose-content-block-v2 {
			&__image {
				order: 1;
			}
			&__main {
				order: 2;
				margin-bottom: map-get($spacers, 4);
			}
			&__image-secondary {
				order: 3;
				margin-bottom: 0;
			}
		}
	}
}