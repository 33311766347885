.project-gallery {
  &__title {
    display: none;
  }

  &__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);

    display: grid;
    margin-bottom: -#{map-get($spacers, 1)};
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: map-get($spacers, 2);
    grid-auto-flow: dense;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: map-get($spacers, 4);
    }

    @include media-breakpoint-up(lg) {
      grid-column-gap: map-get($spacers, 5);
    }

    &__item {
      margin-bottom: map-get($spacers, 2);
      height: fit-content;

      @include media-breakpoint-up(sm) {
        margin-bottom: map-get($spacers, 4);
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: map-get($spacers, 5);
      }
    }
  }

  &__image-crop {

    // old aspect ratio mixin
    // @extend .aspect-ratio;

    position: relative;
    width: 100%;
    height: auto;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
      aspect-ratio: auto;
    }
  }

  &__footer {
    @extend .spacer-top-s;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.masonry {
	position: relative;
	min-height: 400px;
	overflow: hidden;
	
	//@include media-breakpoint-down(sm) {
	//	display: grid;
  //  grid-template-columns: repeat(2, 1fr);
  //  grid-template-rows: 1fr;
  //  grid-gap: map-get($spacers, 2);
	//	height: auto!important;

	//	img {
	//		aspect-ratio: 1;
	//		object-fit: cover;
	//	}
	//}

	&::after {
		content: '';
		@include abs(0);
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
		z-index: 2;
		opacity: 0;
		pointer-events: none;
		transition: opacity .2s ease;
	}

	&.is--reloading {
		opacity: .2;
	}

	&.is--loading {
		min-height: 400px;
		overflow: hidden;

		&::after {
			opacity: 1;
		}

		.masonry__item {
			opacity: 0;
		}
	}
	
	&__item {
		min-height: 50px;
		width: 33%;

		@include media-breakpoint-down(sm) {

			img {
				aspect-ratio: 150/115;
				object-fit: cover;
				padding: 5px;
			}
			img.is--portrait {
				aspect-ratio: 150/230;
			}
		}
		//@include media-breakpoint-down(sm) {
		//	height: auto!important;
		//	width: auto!important;
		//	position: relative!important;
		//	transform: none !important;
		//}
		
		//@include media-breakpoint-up(sm) {
		position: absolute;
		transition: opacity .2s ease;
		//}
	}
}

.w-100 {
	width: 100%;
}
